import { notification, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { maxBounds } from "../../../constants/contants";
import {
  ArrowTriangleDown,
  ToastCrossIcon,
  ToastGreenCheckIcon,
  ToastRedTrashIcon,
} from "../../../constants/svg";
import { GetFlashcards } from "../../../services/flashCards/flashCards";
import { GetAuthUserLocalStorage } from "../../../services/localStorage/localStorage";
import { GetQuiz } from "../../../services/quiz/quiz";
import { GetSalonResources } from "../../../services/resources/resources";
import { GetServey } from "../../../services/survey/survey";
import { GetDocuments } from "../../../services/team/document";
import {
  CreateNewHireCheckList,
  GetSingleNewHireCheckList,
  UpdateNewHireCheckList,
} from "../../../services/team/newHireChecklist";
import {
  setActionButtonDisable,
  setNewHireCheckListAddEditForm,
  setReRenderNewHireDetailPage,
  setReRenderTable,
  setSelectedData,
} from "../../../store/slices/newHireChecklistSlice";
import {
  alert_category_array,
  alert_link_type_array,
  link_category_type,
  link_types,
} from "../../../utils/constants";
import CustomSelect from "../../FormFields/CustomSelect";
import { validURL } from "../../../utils/helper";
import { GetEvents } from "../../../services/events/events";

function CreateNewHireModal(props) {
  const [content, setContent] = useState([]);

  const authUser = GetAuthUserLocalStorage();

  const dispatch = useDispatch();

  const {
    selectedData,
    newHireCheckListAddEditForm,
    actionButtonDisable,
    reRenderTable,
    reRenderNewHireDetailPage,
  } = useSelector((state) => state.newHireCheckList);
  const { currentSalonId } = useSelector((state) => state.global);

  const [laadingInternalLinkCategory, setLoadingInternalLinkCategory] =
    useState(false);
  const [switchCheckValue, setSwitchCheckValue] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    resetField,
    getValues,
    watch,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      onboardingTask: "",
      intructions: "",
      interLink: link_types.no_link,
      link_category: "",
      link_id: "",
      link: "",
    },
  });

  const watchingCategory = watch("link_category");
  const externalLink = watch("link");
  const interLink = watch("interLink");

  const onSubmit = async (inputFields) => {
    const payload = {
      name: inputFields.onboardingTask,
      description: inputFields.intructions,
      link_type: inputFields.interLink,
      user_id: authUser.id,
      salon_id: currentSalonId,
    };

    if (inputFields.interLink == link_types.internal) {
      payload.link_category = inputFields.link_category;
      payload.link_id = inputFields.link_id;
    } else if (inputFields.interLink == link_types.external) {
      payload.link = inputFields.link;
    }

    try {
      dispatch(setActionButtonDisable(true));
      let response;
      if (newHireCheckListAddEditForm.formType == "create") {
        response = await CreateNewHireCheckList(payload);
      } else if (newHireCheckListAddEditForm.formType == "update") {
        response = await UpdateNewHireCheckList(selectedData.id, payload);
      }

      dispatch(setSelectedData(null));

      dispatch(
        setNewHireCheckListAddEditForm({
          show: false,
          formType: null,
        }),
      );

      dispatch(setReRenderTable(reRenderTable + 1));
      dispatch(setReRenderNewHireDetailPage(reRenderNewHireDetailPage + 1));
      let notiMsg;

      if (newHireCheckListAddEditForm.formType == "create") {
        notiMsg = "New hire checklist Created Successfully.";
      } else if (newHireCheckListAddEditForm.formType == "update") {
        notiMsg = "New hire checklist Updated Successfully.";
      }

      notification.success({
        message: response?.data?.message || notiMsg,
        placement: "bottomRight",
        // duration: 0,
        className: "createdNotification",
        icon: <ToastGreenCheckIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
      dispatch(setActionButtonDisable(false));
    }
  };

  const getAllResources = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetSalonResources(params);
      const data = res?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setContent(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const getAllDocuments = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetDocuments(params);
      const data = res?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.title,
      }));
      setContent(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const getAllFlashcards = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetFlashcards(params);
      const data = res?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setContent(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const getAllSurveys = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetServey(params);
      const data = res?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setContent(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const getAllQuizzes = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetQuiz(params);
      const data = res?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setContent(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };
  const getAllEvents = async () => {
    try {
      const params = {
        is_paginated: 0,
        salon_id: currentSalonId,
      };
      const res = await GetEvents(params);
      const data = res?.data?.data?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setContent(data);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    }
  };

  const fetchContent = async () => {
    setLoadingInternalLinkCategory(true);
    setValue("link_id", "Select Content");
    if (watchingCategory == link_category_type?.resources) {
      await getAllResources();
    } else if (watchingCategory == link_category_type?.documents) {
      await getAllDocuments();
    } else if (watchingCategory == link_category_type?.flashcard) {
      await getAllFlashcards();
    } else if (watchingCategory == link_category_type?.surveys) {
      await getAllSurveys();
    } else if (watchingCategory == link_category_type?.quizzes) {
      await getAllQuizzes();
    } else if (watchingCategory == link_category_type?.events) {
      await getAllEvents();
    }
    setLoadingInternalLinkCategory(false);
  };

  useEffect(() => {
    fetchContent();
  }, [watchingCategory, currentSalonId]);

  const handleSwitch = (checked) => {
    if (!checked) {
      setValue("link_category", "");
      setValue("link", "");
      setValue("link_id", "");
      resetField("interLink");
    }

    if (checked) {
      setValue("interLink", link_types.internal);
    }

    setSwitchCheckValue(checked);
  };

  const handleChangeInternalLinkInputType = (e) => {
    setValue("link_category", "");
    setValue("link", "");
    setValue("link_id", "");

    if (e == link_types.no_link) {
      setSwitchCheckValue(false);
    }
  };

  const getNewHireCheckListByID = async () => {
    try {
      const response = await GetSingleNewHireCheckList(selectedData.id);
      const { data, message, status } = response.data;
      if (!status) {
        throw new Error(message);
      }

      setValue("onboardingTask", data.name);
      setValue("intructions", data.description);
      setValue("interLink", data.link_type);

      if (data.link_type == link_types.internal) {
        setSwitchCheckValue(true);
        setValue("link_category", data.link_category);
        setValue("link_id", data.link_id);
      } else if (data.link_type == link_types.external) {
        setSwitchCheckValue(true);
        setValue("link", data.link);
      }
      setDisableSubmit(false);
    } catch (e) {
      notification.error({
        message: <span>{e.message}</span>,
        placement: "bottomRight",
        className: "createdNotification",
        icon: <ToastRedTrashIcon />,
        closeIcon: <ToastCrossIcon />,
      });
    } finally {
    }
  };

  const handleDisableSubmit = () => {
    if (
      getValues("interLink") == link_types.external &&
      !validURL(getValues("link"))
    ) {
      setDisableSubmit(true);
      return true;
    }
    if (
      getValues("interLink") == link_types.internal &&
      !getValues("link_category")
    ) {
      setDisableSubmit(true);
      return true;
    }
    if (actionButtonDisable || !isValid || isSubmitting) {
      setDisableSubmit(true);
      return true;
    } else {
      setDisableSubmit(false);
      return false;
    }
  };
  useEffect(() => {
    handleDisableSubmit();
  }, [isValid]);
  useEffect(() => {
    if (newHireCheckListAddEditForm.formType == "update") {
      getNewHireCheckListByID();
    }
  }, [newHireCheckListAddEditForm]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={`modal-body ${props.customModalBody}`}>
          <Row>
            <Col lg={12}>
              <FloatingLabel
                controlId="onboardingTask"
                label="Onboarding Task"
                className="mb-20 custom-textarea"
              >
                <Form.Control
                  type="text"
                  placeholder="ex. Enroll in Direct Deposit"
                  autoComplete="off"
                  role={"presentation"}
                  className={errors.onboardingTask ? "hasError" : ""}
                  maxLength={maxBounds.TASKNAME}
                  {...register("onboardingTask", {
                    required: "Onboarding task is required",
                  })}
                />
              </FloatingLabel>
            </Col>
            <Col lg={12}>
              <FloatingLabel
                controlId="intructions"
                label="Instructions (Optional)"
                className="mb-20 custom-textarea"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Instructions (Optional)"
                  style={{ height: "140px" }}
                  type="text"
                  autoComplete="off"
                  role={"presentation"}
                  className={errors.intructions ? "hasError" : ""}
                  {...register("intructions")}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <div className="notificationLinkBox noMarginTop newHire mt-4">
            <div className="header d-flex">
              <h3 className={"me-3"}>Add Link (Optional)</h3>{" "}
              <Switch
                defaultChecked={switchCheckValue}
                checked={switchCheckValue}
                onChange={handleSwitch}
                size={"medium"}
              />
            </div>
            <p className={"gray"}>
              Send staff to a website (like PayChex.com) or link to a doc in
              Salon Symphony (like your Employee Contract).
            </p>

            {switchCheckValue && (
              <Row>
                <Col lg={4}>
                  <Controller
                    control={control}
                    name="interLink"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <div
                        className={`onboardscoped-select ${laadingInternalLinkCategory ? "disable" : ""}`}
                      >
                        <CustomSelect
                          name={"interLink"}
                          onChange={(e) => {
                            handleChangeInternalLinkInputType(e);
                            onChange(e);
                          }}
                          placeholder={"Internal Link"}
                          suffixIcon={<ArrowTriangleDown />}
                          options={alert_link_type_array}
                          defaultValue={alert_link_type_array[0]}
                          value={value}
                          className="noLabelSelect myCustomSelect"
                        />
                      </div>
                    )}
                  />
                </Col>
                {getValues("interLink") == link_types.internal && (
                  <>
                    <Col lg={4}>
                      <Controller
                        control={control}
                        name="link_category"
                        rules={{
                          required: "Category is required",
                        }}
                        render={({ field: { onChange, value } }) => (
                          <FloatingLabel
                            controlId="category"
                            label={value == "" ? "Select Category" : ""}
                            className={`onboardscoped-select ${laadingInternalLinkCategory ? "disable" : ""}`}
                          >
                            <CustomSelect
                              value={value}
                              // label="Category"
                              options={alert_category_array}
                              className={
                                value == ""
                                  ? `hasError noLabelSelect myCustomSelect`
                                  : `noLabelSelect myCustomSelect`
                              }
                              onChange={onChange}
                              placeholder="Select Category"
                            />
                          </FloatingLabel>
                        )}
                      />
                    </Col>

                    <Col lg={4} className={"custom-height"}>
                      <Controller
                        control={control}
                        name="link_id"
                        rules={{ required: "Job type is required is required" }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <FloatingLabel
                            controlId="category"
                            label={value == "" ? "Select Content" : ""}
                            className={`onboardscoped-select ${laadingInternalLinkCategory ? "disable" : ""}`}
                          >
                            <CustomSelect
                              className={
                                errors.link_id
                                  ? `hasError noLabelSelect myCustomSelect`
                                  : `noLabelSelect myCustomSelect`
                              }
                              onChange={onChange}
                              value={
                                laadingInternalLinkCategory
                                  ? "Select Content"
                                  : content.length
                                    ? value
                                    : undefined
                              }
                              // value={content.length ? value : ""}
                              placeholder={"Select Content"}
                              suffixIcon={<ArrowTriangleDown />}
                              options={content}
                            />
                          </FloatingLabel>
                        )}
                      />
                      <i className="internal-link-content-dropdown-loading-text">
                        {laadingInternalLinkCategory && "Please wait..."}
                      </i>
                    </Col>
                  </>
                )}

                {getValues("interLink") == link_types.external && (
                  <Col lg={8}>
                    <FloatingLabel controlId="floatingInput" label="https://">
                      <Form.Control
                        type="link"
                        className={errors.link ? "hasError" : ""}
                        placeholder="https://"
                        {...register("link", {
                          required: "Link is required",
                        })}
                      />
                    </FloatingLabel>
                  </Col>
                )}
              </Row>
            )}
            {getValues("interLink") == link_types.internal &&
              switchCheckValue && (
                <p className="gray mt-3 mb-0">
                  Internal Link: Choose any content in your workspace, such as a
                  resource, survey, event, or quiz.
                </p>
              )}
            {getValues("interLink") == link_types.external && (
              <p className="gray mt-3 mb-0">
                External link: Choose any content in your workspace, such as a
                resource, survey, event, or quiz.
              </p>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <Button type="submit" className={"w-100"} disabled={disableSubmit}>
            {actionButtonDisable && <div className="load black" />}
            {!actionButtonDisable &&
            newHireCheckListAddEditForm.formType == "create"
              ? "Add to Checklist"
              : ""}
            {!actionButtonDisable &&
            newHireCheckListAddEditForm.formType == "update"
              ? "Edit Checklist"
              : ""}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default CreateNewHireModal;
