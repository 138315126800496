import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { UserProfileAvatarCamera } from "../../constants/svg";
import { Assets } from "../../constants/images";
import UpdatePassword from "./ProfileForms/UpdatePassword";
import EditProfileBio from "./ProfileForms/EditProfileBio";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAuthUserLocalStorage,
  SetAuthUserLocalStorage,
} from "../../services/localStorage/localStorage";
import { salonUploadToS3, toastMessage } from "../../utils/helper";
import { UpdateUser } from "../../services/users/users";
import { updateFirebaseUser } from "../../services/chats/frontend-chats";
import { setCallGetMyProfileAPI } from "../../store/slices/authSlice";
import AvatarImage from "../../components/Common/AvatarImage";

const ProfilePage = () => {
  const [file, setFile] = useState({ file: null, file_url: null });
  const dispatch = useDispatch();
  const { me, callGetMyProfileAPI } = useSelector((state) => state?.auth);
  const authUser = GetAuthUserLocalStorage();
  useEffect(() => {
    if (file?.file) {
      HandleImageChange();
    }
  }, [file]);
  const HandleImageChange = async () => {
    try {
      let apiData = {
        image: null,
      };
      let salonS3UploadResponse = "";
      if (file?.file) {
        let folder = `${
          GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
        }/`;
        salonS3UploadResponse = await salonUploadToS3(
          file?.file,
          folder,
          false,
          false,
          false,
        );
        if (salonS3UploadResponse.status) {
          apiData.image = salonS3UploadResponse?.data?.location;
        } else {
          throw new Error(salonS3UploadResponse?.message);
        }
      }
      const res = await UpdateUser(authUser?.id, apiData);
      SetAuthUserLocalStorage(res?.data?.data);
      await updateFirebaseUser(res?.data?.data);
      dispatch(setCallGetMyProfileAPI(callGetMyProfileAPI + 1));
      toastMessage("success", res?.data?.message);
    } catch (e) {
      toastMessage("error", e.message);
    }
  };
  return (
    <>
      <div className="profileHead">
        <Row>
          <Col className="profile-details" lg={9}>
            <div>
              <label className="profile-img-wrapper" htmlFor="profile">
                <input
                  className="d-none"
                  onChange={(e) => {
                    setFile({
                      file: e.target.files[0],
                      file_url: URL.createObjectURL(e.target.files[0]),
                    });
                  }}
                  type="file"
                  id="profile"
                  name="profile"
                  accept="image/*"
                />
                <div className="myProfile__img">
                  <AvatarImage
                    name={me?.data?.name}
                    src={file.file_url ? file.file_url : me?.data?.image}
                    size={90}
                  />
                  {/* <img
                    src={
                      file.file_url
                        ? file.file_url
                        : me?.data?.image || Assets.ProfilePlaceholder
                    }
                    alt=""
                    onError={(e) => (e.target.src = Assets.ProfilePlaceholder)}
                  /> */}
                  <div className="cameraIcon">
                    <UserProfileAvatarCamera />
                  </div>
                </div>
              </label>
            </div>
            <div>
              <p className="profile-title" style={{ padding: "25px 0px" }}>
                {me?.data?.name || "-"}
              </p>
              {/* <p className="profile-desc">{me?.data?.position?.name || "-"}</p> */}
            </div>
          </Col>
          {/* <Col className="profile-notify-bar">
            <p>Enable desktop notifications</p>
            <Switch defaultChecked />
          </Col> */}
        </Row>
      </div>
      <div className="profileBody profileBodyWrapper">
        <Row className="h-100">
          <Col lg={4} className="p-0">
            <UpdatePassword />
          </Col>
          <Col lg={8} className="p-0">
            <EditProfileBio file={file} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ProfilePage;
