import { DatePicker } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en"; // Import the English locale for dayjs
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useStateContext } from "../../Contexts/ContextProvider";
import { RoundedCalenderIcon } from "../../constants/svg";

const DatepickerComponent = (props) => {
  const { alertCalander, setAlertCalander } = useStateContext();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [dateValue, setDateValue] = useState();
  const [internalDate, setInternalDate] = useState(null);

  useEffect(() => {
    dayjs.locale("en"); // Set the locale to English
  }, []);

  useEffect(() => {
    if (props.value) {
      setInternalDate(dayjs(props.value));
      setDateValue(props.value);
    }
  }, [props.value]);

  const onChange = (date, dateString) => {
    if (props?.handleSelectAndDatePickerValues) {
      props?.handleSelectAndDatePickerValues(date?.format("YYYY-MM-DD"));
    }
    setInternalDate(date);
    setDateValue(dateString);
    if (props?.setData) {
      props?.setData(props?.name, dateString);
    }
  };

  return (
    <div
      className={`customLabelGroup datePickerWrapper form-floating mb-20 ${dateValue ? "hasValue" : ""} ${props.className || ""}`}
    >
      <div className="form-control">
        {dateValue ? (
          <div
            className={"customLabel"}
            style={{
              display: "block",
              color: isCalendarOpen ? "#7B68EE" : "#A8A8A8",
            }}
          >
            {props?.pickerName}
          </div>
        ) : null}
        <DatePicker
          onChange={onChange}
          suffixIcon={true}
          placeholder={props?.pickerName}
          disabledDate={props?.disabledDate}
          className="customDatePicker"
          closeButton={false}
          value={internalDate} // Use internal state instead of direct prop
          format="MMM DD, YYYY" // Set the desired date format
          onFocus={() => setIsCalendarOpen(true)}
          onBlur={() => setIsCalendarOpen(false)}
          showNow={false}
          allowClear={false}
          onOpenChange={(open) => setIsCalendarOpen(open)}
          onPanelChange={(date) => setInternalDate(date)}
        />
      </div>
      <div className="icon">
        <RoundedCalenderIcon />
      </div>
    </div>
  );
};

export default DatepickerComponent;
