import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { Controller } from "react-hook-form";
import { CirclePinLoc } from "../../../../../constants/svg";
import {
  constant,
  event_times,
  event_timezone,
} from "../../../../../utils/constants";
import { toastMessage } from "../../../../../utils/helper";
import CustomDropdown from "../../../../Common/CustomDropdown";
import DatepickerComponent from "../../../../Common/Datepicker";
import { maxBounds, Patterns } from "../../../../../constants/contants";

const ThirdStep = ({
  register,
  errors,
  control,
  watch,
  getValues,
  setValue,
}) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: constant.GOOGLE_MAP_KEY,
  });

  const watchingStartDate = watch("startDate");
  const watchingEndDate = watch("endDate");
  const eventType = watch("eventType");

  const [showSuggestionEventPlace, setShowSuggestedEventPlace] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [openCalendar, setOpenCalendar] = useState({
    startDate: false,
    endDate: false,
  });
  const [selectedOption, setSelectedOption] = useState({
    startTime: null,
    endTime: null,
    timeZone: null,
  });

  useEffect(() => {
    let convertedDate = moment(selectedDate).format("MM DD, YYYY");
    if (selectedDate) {
      setSelectedDate(convertedDate);
    }
  }, [selectedDate]);

  const handleInputClick = () => {
    setOpenCalendar(true);
  };

  const handleGetPlaceInformationByPlaceId = (e) => {
    const placeId = e.target.dataset.place_id;
    placesService?.getDetails(
      {
        placeId,
      },
      (placeDetails) => {
        const name = placeDetails.name;
        const vicinity = placeDetails.formatted_address;
        const lat = placeDetails.geometry.location.lat();
        const lng = placeDetails.geometry.location.lng();
        const googleMap = placeDetails.url;
        let address = vicinity.replace(/,[^,]*$/, "");
        // Remove name from address if it exists
        if (name && address.includes(name)) {
          // address = address.replace(name + ',', '').trim();
          setValue("eventLocation", address);
        } else {
          setValue("eventLocation", name + " " + address);
        }

        setValue("eventLat", lat.toString());
        setValue("eventLng", lng.toString());
        setValue("eventGoogleMap", googleMap);
        setShowSuggestedEventPlace(false);
      },
    );
  };

  const handleClickScreen = (e) => {};

  useEffect(() => {
    window.addEventListener("click", handleClickScreen);
    return () => {
      window.removeEventListener("click", handleClickScreen);
    };
  }, []);

  const priceRegister = register("price", {
    required: false,
    pattern: {
      value: Patterns.NumberWithDecimal,
    },
    maxLength: {
      value: maxBounds.PRICE,
    },
  });
  return (
    <Row>
      <Col lg={12}>
        {eventType == "onsite" && (
          <>
            <FloatingLabel
              controlId="EventLocations"
              label="Event Location"
              style={{ position: "relative" }}
              className=""
            >
              <Form.Control
                onChange={(evt) => {
                  setShowSuggestedEventPlace(true);
                  setValue("eventLocation", evt.target.value);
                  getPlacePredictions({ input: evt.target.value });
                }}
                type="text"
                placeholder="15 Ocean Drive, Miami Beach, FL 33139"
                name="eventLocation"
                autoComplete="off"
                value={getValues("eventLocation")}
                className={errors.eventLocation ? "hasError" : ""}
              />
              <div className="inputIcon">
                <CirclePinLoc />
              </div>
            </FloatingLabel>
            <div className="event-place-suggestion-wrapper">
              {showSuggestionEventPlace && (
                <ul className="suggested-event-location-ul">
                  {placePredictions.map((item) => (
                    <li
                      className="suggested-event-li"
                      data-place_id={item.place_id}
                      onClick={handleGetPlaceInformationByPlaceId}
                    >
                      {item.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </>
        )}
        {eventType == "online" && (
          <FloatingLabel
            controlId="eventLink"
            label="Event Link"
            className="mb-20"
          >
            <Form.Control
              type="text"
              placeholder="Event Link"
              autoComplete="off"
              role={"presentation"}
              className={errors.eventLink ? "hasError" : ""}
              {...register("eventLink", {
                required: {
                  value: "Event Link is required",
                },
                pattern: {
                  value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
                },
              })}
            />
          </FloatingLabel>
        )}

        <hr className="seperator" />
        <Row>
          <Col lg={4}>
            <Controller
              rules={{
                required: "Start date is required",
              }}
              control={control}
              name="startDate"
              render={({ field: { onChange, value } }) => (
                <DatepickerComponent
                  value={value}
                  defaultValue={value}
                  className={`event-scoped-date-picker ${errors.startDate ? "hasError" : ""}`}
                  disabledDate={(current) => {
                    return current && current < moment().startOf("day");
                  }}
                  handleSelectAndDatePickerValues={(e) => {
                    const startDate = new Date(e);
                    const endDate = new Date(getValues("endDate"));
                    if (startDate.getTime() > endDate.getTime()) {
                      toastMessage(
                        "error",
                        "The start date should not precede the end date.",
                      );
                      return;
                    }
                    if (!getValues("endDate")) {
                      setValue("endDate", e);
                    }
                    onChange(e);
                  }}
                  pickerName={"Start Date"}
                />
              )}
            />
          </Col>
          <Col lg={4}>
            <Controller
              rules={{
                required: "Start time is required",
              }}
              control={control}
              name="startTime"
              render={({ field: { onChange, value } }) => (
                <FloatingLabel
                  controlId="startTime"
                  label={value == "" ? "Start Time" : ""}
                  className="mb-20"
                >
                  <CustomDropdown
                    value={value}
                    defaultValue={value}
                    className={errors.startTime ? "hasError" : ""}
                    handleSelectAndDatePickerValues={(val) => {
                      const startTimeRawString =
                        moment().format("YYYY/MM/DD") + " " + val;
                      const endTimeRawString =
                        moment().format("YYYY/MM/DD") +
                        " " +
                        getValues("endTime");

                      const startTime = new Date(startTimeRawString);
                      const endTime = new Date(endTimeRawString);
                      if (
                        getValues("endTime") &&
                        startTime.getTime() > endTime.getTime()
                      ) {
                        onChange(getValues("endTime"));
                        toastMessage(
                          "error",
                          "The start time cannot be after the end time. Please adjust the start time",
                        );
                        return;
                      }
                      onChange(val);
                    }}
                    placeholder={"Start Time"}
                    menuOption={event_times} // Send the data array for menu
                    show={null} // Show Method of modal if there is any
                  />
                </FloatingLabel>
              )}
            />
          </Col>
          <Col lg={4}>
            <Controller
              rules={{
                required: "Time zone is required",
              }}
              control={control}
              name="timeZone"
              render={({ field: { onChange, value } }) => (
                <FloatingLabel
                  controlId="startTime"
                  label={value == "" ? "Time Zone" : ""}
                  className="mb-20"
                >
                  <CustomDropdown
                    value={value}
                    defaultValue={value}
                    className={errors.timeZone ? "hasError" : ""}
                    handleSelectAndDatePickerValues={onChange}
                    placeholder={"Time Zone"}
                    menuOption={event_timezone} // Send the data array for menu
                    show={null} // Show Method of modal if there is any
                  />
                </FloatingLabel>
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Controller
              rules={{
                required: "End date is required",
              }}
              control={control}
              name="endDate"
              render={({ field: { onChange, value } }) => (
                <DatepickerComponent
                  value={value}
                  defaultValue={value}
                  className={`event-scoped-date-picker ${errors.endDate ? "hasError" : ""}`}
                  disabledDate={(current) => {
                    return current && current.isBefore(moment().startOf("day"));
                  }}
                  handleSelectAndDatePickerValues={(e) => {
                    const endDate = new Date(e);
                    const startDate = new Date(getValues("startDate"));
                    if (endDate.getTime() < startDate.getTime()) {
                      toastMessage(
                        "error",
                        "The end date should not precede the start date",
                      );
                      return;
                    }
                    if (!getValues("startDate")) {
                      setValue("startDate", e);
                    }
                    onChange(e);
                  }}
                  pickerName={"End Date"}
                />
              )}
            />
          </Col>
          <Col lg={4}>
            <Controller
              rules={{
                required: "End time is required",
              }}
              control={control}
              name="endTime"
              render={({ field: { onChange, value } }) => (
                <FloatingLabel
                  controlId="endTime"
                  label={value == "" ? "End Time" : ""}
                  className="mb-20"
                >
                  <CustomDropdown
                    value={value}
                    defaultValue={value}
                    placeholder={"End Time"}
                    className={errors.endTime ? "hasError" : ""}
                    handleSelectAndDatePickerValues={(val) => {
                      const startTimeRawString =
                        moment(getValues("startDate")).format("YYYY/MM/DD") +
                        " " +
                        getValues("startTime");
                      const endTimeRawString =
                        moment(getValues("endDate")).format("YYYY/MM/DD") +
                        " " +
                        val;

                      const startTime = new Date(startTimeRawString);
                      const endTime = new Date(endTimeRawString);
                      if (
                        getValues("startTime") &&
                        endTime.getTime() < startTime.getTime()
                      ) {
                        onChange(getValues("startTime"));
                        toastMessage(
                          "error",
                          "The end time should be later than the start time.",
                        );
                        return;
                      }
                      onChange(val);
                    }}
                    menuOption={event_times} // Send the data array for menu
                    show={null} // Show Method of modal if there is any
                  />
                </FloatingLabel>
              )}
            />
          </Col>
          <Col lg={4}>
            <div style={{ position: "relative" }}>
              <span
                style={{
                  position: "absolute",
                  left: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
                className="gray-2"
              >
                $
              </span>
              <Form.Control
                type="text"
                step="any"
                placeholder="0.00"
                autoComplete="off"
                value={watch("price")}
                role={"presentation"}
                className={errors.price ? "hasError" : ""}
                style={{ paddingLeft: "24px" }}
                {...priceRegister}
                onChange={(e) => {
                  const regex = /^\d+\.?\d{0,2}$/;
                  if (e.target.value === "" || regex.test(e.target.value)) {
                    priceRegister.onChange(e);
                  }
                }}
              />
            </div>
            <i className="gray-2 fs-sm ps-4">
              Leave the price blank for free event.
            </i>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ThirdStep;
